<template>
  <v-form class="px-5" ref="form" v-model="valid" lazy-validation>
    <v-text-field
      v-model="form.companyName"
      label="Company Name"
      required
    ></v-text-field>
    <v-row>
      <v-col cols="3">
        <v-select
          v-model="form.title"
          :items="titleList"
          item-text="value"
          item-value="value"
          label="Title"
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field
          v-model="form.firstName"
          label="First Name"
          required
        ></v-text-field>
      </v-col>
    </v-row>

    <v-text-field
      v-model="form.surname"
      label="Surname"
      required
    ></v-text-field>

    <base-telephone-input
      :required="true"
      label="Landlord Contact Number"
      v-model="form.contactNumber"
    />
    <v-text-field
      v-model="form.email"
      :rules="emailRules"
      label="Landlord Email Address"
      required
    ></v-text-field>
    <v-text-field
      v-model="form.add1"
      label="Address Line 1"
      required
    ></v-text-field>
    <v-text-field
      v-model="form.add2"
      label="Address Line 2"
      required
    ></v-text-field>
    <v-text-field v-model="form.town" label="Town/City" required></v-text-field>
    <v-text-field v-model="form.county" label="County" required></v-text-field>
    <v-row class="align-center">
      <v-col>
        <v-text-field
          v-model="form.postcode"
          label="Postcode"
          placeholder="Enter Postcode"
          required
          @input="postCodeChanged"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <i
              @click="fetchPostCodeAddresses"
              v-on="on"
              v-bind="attrs"
              class="fas fa-lg fa-search-location"
              style="color: #008000; font-size: 1.4em; cursor: pointer"
            ></i>
          </template>
          <span> Find address</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-select
      v-if="showPostcode && !inCorrectPostcodeSelected"
      class="mt-4"
      :items="addresses"
      v-model="postcodeSelected"
      @change="addressSelected"
      label="Select Address"
      prepend-icon="mdi-domain"
    ></v-select>
    <div class="mt-4">
      <v-btn
        depressed
        color="accent"
        @click="submitPreviousTenantDetails"
        block
        dark
        large
      >
        Save Changes
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import {
  mergeExistingEntriesIntoForm,
  changesSaved,
  somethingWentWrong,
} from "@/helpers/utilities"

import BaseTelephoneInput from "@/components/common/BaseTelephoneInput"

export default {
  components: {
    BaseTelephoneInput,
  },
  data() {
    return {
      valid: true,
      showPostcode: false,
      addresses: [],
      addressList: [],
      selectedAddressId: null,
      postcodeSelected: null,
      inCorrectPostcodeSelected: false,

      form: {
        companyName: "",
        title: "",
        firstName: "",
        surname: "",
        add1: "",
        town: "",
        county: "",
        jobTitle: "",
        contactNumber: "",
        email: "",
        postcode: "",
      },
      postCodes: ["Select your address"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    }
  },
  computed: {
    ...mapGetters("forms", ["titleList"]),
    ...mapGetters("ui", ["formEditMode"]),
    ...mapGetters("addresses", ["previousTenant", "selectedAddress"]),
  },
  created() {
    this.bindPreviousTenantDetails()
    this.fetchTitles()
  },
  whatch: {
    previousTenant() {
      this.bindPreviousTenantDetails()
    },
    formEditMode(value) {
      if (!value) {
        this.$refs.form.reset()
      } else {
        this.bindPreviousTenantDetails()
      }
    },
  },
  watch: {
    previousTenant(newVal, oldVal) {
      if (newVal.previousTenantId !== oldVal.previousTenantId) {
        this.bindPreviousTenantDetails()
      }
    },
  },
  methods: {
    ...mapActions("ui", ["closeForm"]),
    ...mapActions("forms", ["fetchTitles"]),
    ...mapActions("addresses", [
      "updatePreviousTenant",
      "fetchAddressesByPostcode",
    ]),
    bindPreviousTenantDetails() {
      if (this.formEditMode) {
        Object.entries(this.previousTenant).forEach(
          mergeExistingEntriesIntoForm(this.form)
        )
      }
    },
    postCodeChanged() {
      this.form.postcode = this.form.postcode.toUpperCase()
    },
    async fetchPostCodeAddresses() {
      const response = await this.fetchAddressesByPostcode(this.form.postcode)
      if (response.status === 200) {
        this.inCorrectPostcodeSelected = false
        this.showPostcode = true
        this.addresses = response.addresses
      } else {
        this.inCorrectPostcodeSelected = true
        this.showPostcode = false
      }
    },
    addressSelected() {
      const splitPostCode = this.postcodeSelected.split(",")
      if (this.postcodeSelected !== "Select your address") {
        this.form.add1 = splitPostCode[0].trim()
        this.form.add2 = splitPostCode[1].trim()
        this.form.town = splitPostCode[2].trim()
        splitPostCode.length - 1 === 3
          ? (this.form.county = splitPostCode[3].trim())
          : (this.form.county = "")
      }
    },
    async submitPreviousTenantDetails() {
      try {
        const response = await this.updatePreviousTenant({
          ...this.previousTenant,
          ...this.form,
        })
        if (response && response.status === 200) {
          changesSaved()
          this.closeForm()
        } else {
          somethingWentWrong()
        }
      } catch (error) {
        somethingWentWrong()
      }
    },
  },
}
</script>
